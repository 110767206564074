<template>
  <section>
    <title-section
      v-if="!loading"
      :title="guideDetail.name"
      :modeEdit="modeEdit"
      returnText="Regresar"
      :returnToFunction="returnPrevView"
      @set-value="setValue"
    >
      <template v-if="modeEdit">
        <div class="level-item">
          <button class="button button_micro_dark_outlined" :disabled="loadingBtn" @click="modeEdit = false">
            CANCELAR
          </button>
        </div>
        <div class="level-item">
          <button class="button button_micro_dark" :disabled="loadingBtn" :class="{ 'is-loading' : loadingBtn }" @click="updateSelectionGuideAction">
            GUARDAR
          </button>
        </div>
      </template>
      <template v-else>
        <div class="level-item">
          <button class="button button_micro_dark" @click="modeEdit = true">
            EDITAR
          </button>
        </div>
      </template>
    </title-section>
    <template v-if="loading">
      <Loading />
    </template>
    <template v-else>
      <selection-guide-main-detail
        ref="selectionUpdate"
        :guidesProductList="productsGuides"
        :guideDetail="guideDetail"
        :modeEdit="modeEdit"
      />
    </template>
  </section>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'SelectionGuideDetail',
  components: {
    TitleSection: () => import('@/components/UI/TitleSection.vue'),
    SelectionGuideMainDetail: () => import('@/components/SelectionGuide/Detail/SelectionGuideMainDetail.vue'),
    Loading: () => import('@/components/UI/Loading.vue')
  },
  data () {
    return {
      loading: false,
      loadingBtn: false,
      modeEdit: false,
      hasError: false,
      selectionGuideId: null,
      backupSelectionDetail: null,
      ASSETS_URL: process.env.VUE_APP_URL_FILES,
      productsGuides: [],
      guideDetail: {
        id: null,
        name: null,
        description: null,
        creationDate: null,
        status: true,
        coverImage: null,
        guidePDF: null,
        relatedProducts: []
      }
    }
  },
  methods: {
    ...mapActions(['returnPrevView', 'getProductsGuide', 'getSelectionGuideDetail', 'updateSelectionGuide']),
    setValue (event) {
      this.guideDetail.name = event.value
    },
    async getSelectionGuideDetailAction () {
      const { payload } = await this.getSelectionGuideDetail(this.selectionGuideId)
      if (!payload) {
        this.hasError = true
      } else {
        this.backupSelectionDetail = payload
        await this.spreadData(this.backupSelectionDetail)
      }
      this.loading = false
      this.modeEdit = false
    },
    async spreadData (data) {
      this.guideDetail.id = data.guides.id
      this.guideDetail.name = data.guides.guideName
      this.guideDetail.description = data.guides.description
      this.guideDetail.creationDate = data.guides.created_at
      this.guideDetail.status = data.guides.active
      this.guideDetail.coverImage = data.guides.cover_image ? `${this.ASSETS_URL}${data.guides.cover_image}` : null
      this.guideDetail.guideImage = data.guides.guide_file ? `${this.ASSETS_URL}${data.guides.guide_file}` : null
      this.guideDetail.guidePDF = data.guides.pdf_filename
      this.guideDetail.relatedProducts = data.relatedProducts
    },
    async updateSelectionGuideAction () {
      if (await this.$validator.validateAll()) {
        this.loadingBtn = true
        const dataUpdateSelectionGuide = await this.$refs.selectionUpdate.getDataContruct()
        const { localDescription, localStatus } = dataUpdateSelectionGuide.generalData
        dataUpdateSelectionGuide.guideName = this.guideDetail.name
        dataUpdateSelectionGuide.description = localDescription
        dataUpdateSelectionGuide.active = localStatus
        dataUpdateSelectionGuide.guideId = this.selectionGuideId
        const resp = await this.updateSelectionGuide(dataUpdateSelectionGuide)
        if (resp) {
          this.loading = true
          await this.getSelectionGuideDetailAction()
        }
        this.loadingBtn = false
      }
    }
  },
  async beforeMount () {
    this.selectionGuideId = await this.$route.params.selectionGuideId
    this.loading = true

    const dataProducts = await this.getProductsGuide()
    if (dataProducts.success) {
      this.productsGuides = dataProducts.guides
    }

    await this.getSelectionGuideDetailAction()
  }
}
</script>

<style lang="scss" scoped>
  .button {
    font-size: 14px;
    width: 150px;
  }
</style>
